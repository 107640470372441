import {PIROEntityTypes} from '@/enums/PIROEntityTypes'
import {formActions} from '@/enums/formActions'

const productInitState = () => ({
  name: '',
  subItems: [],
  variants: [],
  productImageURLs: [],
})

const initState = () => ({
  showFilter: false,
  products: [],
  attributes: [],
  product: productInitState(),
  category: {},
  priceCancelToken: null,
  productReplacedWithChildItemType: false
})

export const state = initState

export const getters = {
  getMetalSubItemID(state) {
    if (
      state.product.subItems.some(
        (si) => si.attachedEntityTypeId === PIROEntityTypes.MasterAlloys
      )
    ) {
      return state.product.subItems.find(
        (si) => si.attachedEntityTypeId === PIROEntityTypes.MasterAlloys
      ).itemTypeID
    }
    return 0
  },
  getSubItems(state) {
    return state.product.subItems
  },
  getVariants(state) {
    return state.product.variants
  },
  productHasMetalSubitem(state) {
    return state.product.subItems.some(
      (s) => s.attachedEntityTypeId === PIROEntityTypes.MasterAlloys
    )
  },
}

export const mutations = {
  setProducts(state, {products}) {
    state.products = products
  },
  addProducts(state, {products}) {
    state.products.push(...products)
  },
  addAttributes(state, {attributes}) {
    state.attributes.push(...attributes)
  },
  setProduct(state, {product}) {
    state.product = product
  },
  setProductPrice(state, price) {
    state.product.price = price
  },
  setPriceCancelToken(state, priceCancelToken) {
    state.priceCancelToken = priceCancelToken
  },
  reset(state) {
    Object.assign(state, initState())
  },
  setProductImages(state, {images}) {
    state.product.productImageURLs = []
    state.product.productImageURLs = [...images]
  },
  setCategory(state, {category}) {
    state.category = category
  },
  initCategory(state) {
    state.category = {
      name: '',
    }
  },
  setProductByChildItemType(state, childItemType) {
    state.productReplacedWithChildItemType = false;

    if (childItemType && childItemType.id > 0) {
      state.product.id = childItemType.id;
      state.product.code = childItemType.code;
      state.product.name = childItemType.name;
      state.productReplacedWithChildItemType = true;
    }
  }
}

export const actions = {
  async getProducts({commit}, {id, filters}) {
    commit('setProducts', {products: []})
    const products = await this.$axios.$get(
      `/productCategories/products?categoryId=${id}`
    )
    commit('setProducts', {products})
  },
  async getCategoryById({commit}, id) {
    commit('initCategory')
    const category = await this.$axios.$get(`/productCategories/${id}`)
    commit('setCategory', {category})
  },
  getFilteredProducts({commit}, {id, itemGroupID, filters, pagingInfo}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          let url = `/productCategories/products?categoryId=${id}`
          const attributeFilters = []
          filters.map((f) => {
            if (f.value) {
              if (f.property.indexOf('ATTR_') == 0) {
                attributeFilters.push({
                  value: f.value,
                  attributeCode: f.property.replace('ATTR_', ''),
                })
              } else {
                url = url + `&${f.property}=${f.value}`
              }
            }
            if(f.valuefrom){
              url = url + `&${f.property}from=${f.valuefrom}`;
            }
            if(f.valueto){
              url = url + `&${f.property}to=${f.valueto}`;
            }
          })
          url = url + `&attributeFilterStr=${JSON.stringify(attributeFilters)}`
          url = url + `&itemGroupID=${itemGroupID}`
          url = url + `&pageNo=${pagingInfo.pageNo}&pageSize=${pagingInfo.pageSize}`
          const products = await this.$axios.$get(url)
          if (pagingInfo.pageNo === 0) {
            commit('setProducts', {products})
          } else {
            commit('addProducts', {products})
          }
          resolve(products)
        },
        formActions.Load,
        'products'
      )
    })
  },
  getFilterAttributes({commit}, {id}) {
    return new Promise((resolve, reject) => {
      this.$catch(
        async () => {
          const url = `/productCategories/filterAttributes?itemGroupID=${id}`

          const attributes = await this.$axios.$get(url)

          commit('addAttributes', {attributes})

          resolve(attributes)
        },
        formActions.Load,
        'attributes'
      )
    })
  },
  async getProductById({commit}, productId) {
    commit('setProduct', {product: productInitState()})
    const product = await this.$axios.$get(
      `/itemtypes/${productId}?includesubitems=true&includevariants=true`
    )
    commit('setProduct', {product})
  },
  async updateProductPrice({commit, state}, {priceCalculationData}) {
    if (state.priceCancelToken) {
      state.priceCancelToken.cancel()
    }
    const priceCancelToken = this.$axios.CancelToken.source()
    commit('setPriceCancelToken', priceCancelToken)
    const price = await this.$axios.$post(
      `/products/cp/calculatePrice`,
      priceCalculationData,
      {
        cancelToken: priceCancelToken.token,
      }
    )
    commit('setProductPrice', price)
  },
  async updateProductImages({commit}, {productCode, metalId}) {
    const images = await this.$axios.$get(
      `/products/updateProductImages?productCode=${productCode}&metalId=${metalId}`
    )
    commit('setProductImages', {images})
  },
  async getItemTypeByMasterItemTypeAttributes({commit}, {productId, attributeValues}) {
    const response = await this.$axios.$post(
      '/products/getChildItemByMasterItemTypeAttributes',
      {
        itemTypeID: productId,
        attributes: attributeValues
      });
    commit('setProductByChildItemType', response.value)
  }
}
