const initState = () => ({
  shippingMethods: [],
})

export const state = initState

export const getters = {
  getShippingMethods: (state) => (code) => {
    const result = state.shippingMethods.find(
      (element) => element.code === code.code
    )
    return result?.value
  },
}

export const mutations = {
  setShippingMethods(state, {shippingMethods}) {
    state.shippingMethods = shippingMethods
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async fetchShippingMethods({commit}, {destinationAddress, cancelToken}) {
    return await this.$axios
      .$post(
        `/ShippingMethods/shippingMethodsForCheckout`,
        destinationAddress,
        {
          cancelToken: cancelToken?.token,
        }
      )
      .then((shippingMethods) => {
        commit('setShippingMethods', {
          shippingMethods,
        })
      })
      .catch((ex) => {
        if (this.$axios.isCancel(ex)) {
          console.log('Request canceled', ex.message)
        }
      })
  },
}
