const initState = () => ({
  payments: [],
  page: 1,
  totalItems: 0,
  options: {},
  creditCards: [],
})

export const state = initState

export const mutations = {
  setPayments(state, {payments}) {
    state.payments = payments
  },
  setOptions(state, {options}) {
    state.options = options
  },
  setTotalItems(state, {totalItems}) {
    state.totalItems = totalItems
  },
  setCreditCards(state, {creditCards}) {
    state.creditCards = creditCards
  },
  addNewCreditCard(state, {creditCard}) {
    state.creditCards.unshift(creditCard)
  },
}

export const actions = {
  // This function tokenizes a payment method.
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It is up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  async tokenize({commit},{paymentMethod}) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
  },
  async postPayment({commit}, {paymentData}) {
    const paymentResult = await this.$axios.$post(`/payment/pay`, paymentData)
    return paymentResult
  },

  async createOrderAndPay({commit}, {paymentData}) {
    try {
      return await this.$axios.$post(`/payment/createorderandpay`, paymentData)
    } catch (error) {
      console.log(error)
    }
  },
  async createOrdersAndPay({commit}, {paymentData}) {
    try {
      return await this.$axios.$post(`/payment/insertOrdersAndPay`, paymentData)
    } catch (error) {
      console.log(error)
    }
  },

  async fetchPayments({commit, state}, {filters}) {
    const {page, itemsPerPage} = state.options
    const result = await this.$axios.$get(
      '/Payment?pageSize=' + itemsPerPage + '&pageNo=' + (page - 1) + filters
    )
    commit('setPayments', {payments: result.items})
    commit('setTotalItems', {totalItems: result.pagingData.itemCount})

    return result
  },
  async fetchCreditCards({commit}) {
    const creditCards = await this.$axios.$get('/CreditCard')
    commit('setCreditCards', {creditCards})
  },
}
